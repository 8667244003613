import {itemConstants} from '../_constants';
import {itemService} from '../_services';
import {alertActions} from './alert.actions'

export const itemActions = {
    getItems,
    deleteItem
};

function getItems(userId, page, size) {
    return dispatch => {
        dispatch(request());

        itemService.getItems(userId, page, size)
            .then(
                items => {
                    dispatch(success(items));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };

    function request() {
        return {type: itemConstants.GET_ITEMS_REQUEST}
    }

    function success(items) {
        return {type: itemConstants.GET_ITEMS_SUCCESS, items}
    }

    function failure(error) {
        return {type: itemConstants.GET_ITEMS_FAILURE, error}
    }
}

function deleteItem(itemId) {
    return dispatch => {
        dispatch(request());

        itemService.deleteItem(itemId)
            .then(
                () => {
                    dispatch(success());
                    window.location.reload(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };

    function request() {
        return {type: itemConstants.DELETE_ITEM_REQUEST}
    }

    function success() {
        return {type: itemConstants.DELETE_ITEM_SUCCESS}
    }

    function failure(error) {
        return {type: itemConstants.DELETE_ITEM_FAILURE, error}
    }
}
