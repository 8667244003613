import {userConstants} from '../_constants';

export function user(state = {}, action) {
    switch (action.type) {
        // get users
        case userConstants.GET_USERS_REQUEST:
            return {
                usersLoading: true
            };
        case userConstants.GET_USERS_SUCCESS:
            return {
                usersLoading: false,
                users: action.users.map(user => {
                    user.deleted = user.deleted ? 'Yes' : 'No';
                    return user;
                })
            };
        case userConstants.GET_USERS_FAILURE:
            return {};

        // delete user by id
        case userConstants.DELETE_USER_REQUEST:
            return {
                ...state,
                usersDeleting: true
            };
        case userConstants.DELETE_USER_SUCCESS:
            const deletedUser = state.users.filter(x => x.id === action.userId)[0];
            deletedUser.deleted = 'Yes';
            const newArr = state.users.filter(x => x.id !== action.userId);
            newArr.push(deletedUser);
            return {
                users: newArr,
                usersDeleting: false
            };
        case userConstants.DELETE_USER_FAILURE:
            return {...state};

        default:
            return state
    }
}
