import React from 'react';
import {history} from '../_helpers';
import {alertActions} from "../_actions";
import {Route, Router} from 'react-router-dom';
import {LoginPage} from "../LoginPage";
import {connect} from 'react-redux';
import {PrivateRoute} from "../_components";
import {HomePage} from "../HomePage";

class App extends React.Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        history.listen(() => {
            dispatch(alertActions.clear());
        });
    }

    hideAlert = () => {
        const {dispatch} = this.props;
        dispatch(alertActions.clear());
    }

    render() {
        const {alert} = this.props;
        return (
            <div className="h-100 alert-container">
                {alert.message &&
                <div className={`alert ${alert.type} alert-dismissible`} role="alert">{alert.message.message}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                            onClick={this.hideAlert}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>}
                <Router history={history}>
                    <div className="h-100">
                        <PrivateRoute exact path="/" component={HomePage}/>
                        <Route path="/login" component={LoginPage}/>
                    </div>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
