import axios from "axios";
import {backendServerUrl} from "../config"

export const userService = {
    getUsers,
    deleteUser
}

const GET_USERS_ENDPOINT = 'api/users'
const DELETE_USER_ENDPOINT = 'api/users'

function getUsers(page, size) {
    const token = localStorage.getItem("token");
    return axios.get(`${backendServerUrl}/${GET_USERS_ENDPOINT}?page=${page}&size=${size}`, {
            headers: {
                'Authorization': `Token:${token}`
            }
        }
    );
}

function deleteUser(userId) {
    const token = localStorage.getItem("token");
    return axios.delete(`${backendServerUrl}/${DELETE_USER_ENDPOINT}/${userId}`, {
            headers: {
                'Authorization': `Token:${token}`
            }
        }
    );
}
