import {userConstants} from '../_constants';
import {userService} from '../_services';
import {alertActions} from './alert.actions'

export const userActions = {
    getUsers,
    deleteUser
};

function getUsers(page, size) {
    return dispatch => {
        dispatch(request());

        userService.getUsers(page, size)
            .then(
                users => {
                    dispatch(success(users.data.data.filter(x => x.role !== 'SERVICE')));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };

    function request() {
        return {type: userConstants.GET_USERS_REQUEST}
    }

    function success(users) {
        return {type: userConstants.GET_USERS_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.GET_USERS_FAILURE, error}
    }
}

function deleteUser(userId) {
    return dispatch => {
        dispatch(request());

        userService.deleteUser(userId)
            .then(
                () => {
                    dispatch(success(userId));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };

    function request() {
        return {type: userConstants.DELETE_USER_REQUEST}
    }

    function success(userId) {
        return {type: userConstants.DELETE_USER_SUCCESS, userId}
    }

    function failure(error) {
        return {type: userConstants.DELETE_USER_FAILURE, error}
    }
}
