import {itemConstants} from '../_constants';

export function item(state = {}, action) {
  switch (action.type) {
    case itemConstants.GET_ITEMS_REQUEST:
      return {
        itemsLoading: true
      };
    case itemConstants.GET_ITEMS_SUCCESS:
      return {
        itemsLoading: false,
        items: action.items
      };
    case itemConstants.GET_ITEMS_FAILURE:
      return {};

    case itemConstants.DELETE_ITEM_REQUEST:
      return {
        ...state,
        itemsDeleting: true
      };
    case itemConstants.DELETE_ITEM_SUCCESS:
      return {
        ...state,
        itemsDeleting: false,
      };
    case itemConstants.DELETE_ITEM_FAILURE:
      return {...state};

    default:
      return state
  }
}
