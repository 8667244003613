import axios from "axios";
import {backendServerUrl} from "../config"

export const itemService = {
    getItems,
    deleteItem
}

const GET_ITEMS_ENDPOINT = 'api/items/user'
const DELETE_ITEM_ENDPOINT = 'api/items'

function getItems(userId, page, size) {
    const token = localStorage.getItem("token");
    return axios.get(`${backendServerUrl}/${GET_ITEMS_ENDPOINT}/${userId}?page=${page}&size=${size}`, {
            headers: {
                'Authorization': `Token:${token}`
            }
        }
    );
}

function deleteItem(itemId) {
    const token = localStorage.getItem("token");
    return axios.delete(`${backendServerUrl}/${DELETE_ITEM_ENDPOINT}/${itemId}`, {
            headers: {
                'Authorization': `Token:${token}`
            }
        }
    );
}
