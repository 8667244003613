import axios from "axios";
import {backendServerUrl} from "../config"

export const authService = {
    login,
    logout,
    getProfile
}

const LOGIN_ENDPOINT = 'api/sign-in'
const GET_PROFILE_ENDPOINT = 'api/getProfile'

function login(email, password) {
    return axios.post(`${backendServerUrl}/${LOGIN_ENDPOINT}`,
        {
            email: email,
            password: password
        }
    ).then(response => {
        localStorage.setItem('token', response.data.token);
        return response;
    })
}

function logout() {
    localStorage.removeItem('token');
}

function getProfile() {
    const token = localStorage.getItem("token");
    return axios.get(`${backendServerUrl}/${GET_PROFILE_ENDPOINT}`, {
            headers: {
                'Authorization': `Token:${token}`
            }
        }
    );
}
