import {combineReducers} from 'redux';
import {alert} from "./alert.reducer";
import {auth} from "./auth.reducer";
import {user} from "./user.reducer";
import {item} from "./item.reducer";

const rootReducer = combineReducers({
    alert,
    auth,
    user,
    item
});

export default rootReducer;
