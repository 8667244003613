import React from 'react';
import {connect} from 'react-redux';
import {authActions} from '../_actions'
import {Link} from 'react-router-dom';
import {UsersPage} from "../UsersPage/UsersPage";
import {BoxArrowRight} from "react-bootstrap-icons";
import './style.css';

class HomePage extends React.Component {

    state = {
        activeAdminTab: 'Users'
    };

    componentDidMount() {
        this.props.dispatch(authActions.getProfile());
    }

    handleLogout = () => {
        this.props.dispatch(authActions.logout());
    }

    render() {
        const {user} = this.props;
        const {activeAdminTab} = this.state;
        return (
            <div className="header-container h-100">
                <div className="row justify-content-around header">
                    <div className="col-sm-10">
                        {user.userLoading && <em>Loading users...</em>}
                        Welcome {!user.userLoading && user.user && <b> {user.user.data.name} !</b>}
                    </div>
                    <div className="logout-btn col-sm-2 text-right">
                        <Link to="/login" onClick={this.handleLogout}><BoxArrowRight size={25}/></Link>
                    </div>
                </div>
                {user.user && user.user.data && user.user.data.role === 'ADMIN' && activeAdminTab === 'Users' &&
                <UsersPage/>
                }

            </div>
        );
    }

    handleAdminTabChange = (event) => {
        const target = event.target;
        const tab = target.id;
        this.setState({activeAdminTab: tab})
    }
}

function mapStateToProps(state) {
    const user = state.auth;
    return {
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export {connectedHomePage as HomePage};
