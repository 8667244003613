import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../_actions'
import DataTable from 'react-data-table-component';
import {PostsPage} from "../PostsPage/PostsPage";
import {Modal} from "../shared/Modal";
import {Trash3} from "react-bootstrap-icons";
import './style.css';

const PAGE_SIZE = 10;

const customStyles = {
    headCells: {
        style: {
            fontSize: '15px',
            fontWeight: '600',
            color: '#FFFFFF',
            background: '#858DA2',
            borderRadius: 'none',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
        },
    },
    rows: {
        style: {
            minHeight: '55px',
        },
    },
};

class UsersPage extends React.Component {
    state = {
        showPostsModal: false,
        selectedUserId: null,
        deleteUserModal: false
    };

    columns = [
        {selector: 'name', name: 'Name', sortable: true, width: '15%'},
        {selector: 'email', name: 'Email', sortable: true, width: '23%', cell: row => (
                <div data-tag="allowRowEvents" title={row.email}>
                    {row.email}
                </div>
            )},
        {selector: 'phone', name: 'Phone', sortable: true, width: '16%'},
        {selector: 'address', name: 'Address', sortable: true, width: '18%'},
        {selector: 'role', name: 'Role', sortable: true, width: '15%'},
        {selector: 'deleted', name: 'Deleted', sortable: true, width: '8%'},
        {
            selector: 'delete', width: '5%', cell: row => (
                <span id={row['id']} onClick={this.showDeleteUserModal}>
                    {row.deleted === 'No' &&
                    <Trash3 id={row['id']}/> }
                </span>
            )
        },
    ];

    componentDidMount() {
        this.props.dispatch(userActions.getUsers(0, 99999));
    }

    render() {
        const {users, usersLoading} = this.props;
        const {showPostsModal, selectedUserId, deleteUserModal} = this.state;
        return (
            <div className="users user-container">
                {users &&
                    <DataTable
                        columns={this.columns}
                        data={users}
                        noHeader={true}
                        customStyles={customStyles}
                        highlightOnHover
                        pagination
                        progressPending={usersLoading}
                        paginationPerPage={PAGE_SIZE}
                        paginationComponentOptions={{
                            noRowsPerPage: true,
                        }}
                        onRowClicked={row => this.showPostsModal(row)}
                    />
                }

                {showPostsModal &&
                    <Modal show={showPostsModal} handleClose={this.hidePostsModal} name="User's posts">
                        <PostsPage userId={selectedUserId}/>
                    </Modal>
                }

                <Modal show={deleteUserModal} handleClose={this.hideDeleteUserModal} name={"Confirm delete user"}>
                    <div className="modal-body">
                        <p>Do you really want to delete this user ? Please be aware that all user's posts
                            will be deleted along with the user</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.deleteUser}>Yes</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={this.hideDeleteUserModal}>No
                        </button>
                    </div>
                </Modal>

            </div>
        );
    }

    showDeleteUserModal = (e) => {
        const userId = e.currentTarget.id;
        console.log(userId);
        this.setState({deleteUserModal: true, selectedUserId: parseInt(userId)});
    };

    showPostsModal = (userRow) => {
        const userId = userRow.id;
        this.setState({showPostsModal: true, selectedUserId: parseInt(userId)})
    };

    deleteUser = (userRow) => {
        const userId = this.state.selectedUserId;
        this.props.dispatch(userActions.deleteUser(userId));
        this.hideDeleteUserModal();
    };

    hideDeleteUserModal = () => {
        this.setState({deleteUserModal: false});
    };

    hidePostsModal = () => {
        this.setState({showPostsModal: false})
    };


    setActive = (e) => {
        this.setState({
            activePage: parseInt(e.target.textContent, 10)
        });

        this.props.dispatch(userActions.getUsers(parseInt(e.target.textContent, 10) - 1, PAGE_SIZE));
    };
}


function mapStateToProps(state) {
    const {users} = state.user;
    return {
        users
    };
}

const connectedUsersPage = connect(mapStateToProps)(UsersPage);
export {connectedUsersPage as UsersPage};
