import React from 'react';
import './style.css';

export const Modal = ({show, children, name, handleClose}) => {

    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main rounded-left rounded-right">
                <div className="modal-header">
                    <h4> {name} </h4>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="form-data">
                    {children}
                </div>
            </section>
        </div>
    );
};

