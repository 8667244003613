import {authConstants} from '../_constants';
import {authService} from '../_services';
import {history} from '../_helpers';
import {alertActions} from './alert.actions'

export const authActions = {
    login,
    logout,
    getProfile
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));

        authService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    console.log(error.response.data.error);
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };

    function request(user) {
        return {type: authConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: authConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: authConstants.LOGIN_FAILURE, error}
    }
}

function logout(message) {
    authService.logout();
    return {type: authConstants.LOGOUT, message};
}

function getProfile() {
    return dispatch => {
        dispatch(request());
        authService.getProfile()
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.response.data.error));
                    history.push('/login');
                }
            );
    };

    function request() {
        return {type: authConstants.GET_PROFILE_REQUEST}
    }

    function success(user) {
        return {type: authConstants.GET_PROFILE_SUCCESS, user}
    }

    function failure(error) {
        return {type: authConstants.GET_PROFILE_FAILURE, error}
    }
}

