import React from 'react';
import {connect} from 'react-redux';
import Pagination from 'react-bootstrap/Pagination'
import {itemActions, userActions} from "../_actions";
import './style.css';
import {Modal} from "../shared/Modal";
import {Trash3} from "react-bootstrap-icons";

const PAGE_SIZE = 3;

class PostsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: [1, 2, 3],
            activePage: 1,
            deleteUserModal: false,
            deletePostModal: false,
            selectedPostForDelete: null
        };
    }

    componentDidMount() {
        this.props.dispatch(itemActions.getItems(this.props.userId, 0, PAGE_SIZE));
    }

    render() {
        const {items} = this.props;
        const {deleteUserModal, deletePostModal} = this.state;
        return (
            <div className="container h-100 w-100">
                <div className="h-75">

                    {items && items.data && items.data.data.length === 0 &&
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>Unfortunately there are no posts exist for this user!</strong>
                        </div>
                    }

                    {items && items.data && items.data.data.length > 0 &&
                        <table className="table table-sm table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Product ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Item picture</th>
                                <th scope="col">Description</th>
                                <th scope="col">Price</th>
                                <th scope="col">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items && items.data && items.data.data.length > 0 &&
                                items.data.data.map(item => this.renderPost(item))}
                            </tbody>
                        </table>
                    }
                </div>

                <Modal show={deleteUserModal} handleClose={this.hideDeleteUserModal} name={"Confirm delete user"}>
                    <div className="modal-body">
                        <p>Do you really want to delete this user ? Please be aware that all user's posts
                            will be deleted along with the user</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.deleteUser}>Yes</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={this.hideDeleteUserModal}>No
                        </button>
                    </div>
                </Modal>

                <Modal show={deletePostModal} handleClose={this.hideDeletePostModal} name={"Confirm delete user"}>
                    <div className="modal-body">
                        <p>Do you really want to delete the selected post? </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.deletePost}>Yes</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={this.hideDeletePostModal}>No
                        </button>
                    </div>
                </Modal>

                <div className="float-right">
                    {items && items.data &&
                        <Pagination>
                            <Pagination.First onClick={this.firstPage}/>
                            <Pagination.Prev onClick={this.prevPage}/>
                            <Pagination.Item active={this.state.activePage === this.state.page[0]}
                                             disabled={items.data.totalSize < PAGE_SIZE}
                                             onClick={this.setActive}> {this.state.page[0]} </Pagination.Item>
                            <Pagination.Item active={this.state.activePage === this.state.page[1]}
                                             disabled={items.data.totalSize < this.state.page[1] * PAGE_SIZE}
                                             onClick={this.setActive}> {this.state.page[1]} </Pagination.Item>
                            <Pagination.Item active={this.state.activePage === this.state.page[2]}
                                             disabled={items.data.totalSize < (this.state.page[2]) * PAGE_SIZE}
                                             onClick={this.setActive}> {this.state.page[2]} </Pagination.Item>
                            <Pagination.Next onClick={this.nextPage}/>
                            <Pagination.Last onClick={this.lastPage}/>
                        </Pagination>
                    }
                </div>
            </div>
        );
    }

    renderPost(post) {
        return (
            <tr key={post.id} id={post.id}>
                <td>{post.id}</td>
                <td>{post.title}</td>
                <td><img src={post.image} alt="item_image" className="item-image"/></td>
                <td>{post.description}</td>
                <td>{post.price}</td>
                <td onClick={this.showDeletePostModal}><Trash3/></td>
            </tr>
        );
    }

    deleteUser = () => {
        const {userId} = this.props;
        this.props.dispatch(userActions.deleteUser(userId));
        this.hideDeleteUserModal();
    };

    deletePost = () => {
        const {selectedPostForDelete} = this.state;
        this.props.dispatch(itemActions.deleteItem(selectedPostForDelete));
        this.hideDeletePostModal();
    };

    showDeletePostModal = (e) => {
        const postId = e.target.parentNode.parentNode.id;
        this.setState({deletePostModal: true, selectedPostForDelete: postId});
    };

    hideDeletePostModal = () => {
        this.setState({deletePostModal: false});
    };


    showDeleteUserModal = () => {
        this.setState({deleteUserModal: true});
    };

    hideDeleteUserModal = () => {
        this.setState({deleteUserModal: false});
    };

    prevPage = () => {
        if (this.state.page[0] !== 1) {
            const newPages = this.state.page.map(x => x - 1);
            const userId = this.props.userId;

            this.setState({
                page: newPages,
                activePage: newPages[0]
            });

            this.props.dispatch(itemActions.getItems(userId, newPages[0] - 1, PAGE_SIZE));
        }
    };


    nextPage = () => {
        const {items} = this.props;
        const totalSize = items.data.totalSize;
        const newPages = this.state.page.map(x => x + 1);
        const userId = this.props.userId;

        if (totalSize > this.state.page[2] * PAGE_SIZE) {
            this.setState({
                page: newPages,
                activePage: newPages[2]
            });

            this.props.dispatch(itemActions.getItems(userId, newPages[2] - 1, PAGE_SIZE));
        }
    };

    setActive = (e) => {
        this.setState({
            activePage: parseInt(e.target.textContent, 10)
        });

        this.props.dispatch(itemActions.getItems(this.props.userId, parseInt(e.target.textContent, 10) - 1, PAGE_SIZE));
    };

    firstPage = () => {
        const newPages = [1, 2, 3];
        this.setState({
            page: newPages,
            activePage: newPages[0]
        });
        this.props.dispatch(itemActions.getItems(this.props.userId, newPages[0] - 1, PAGE_SIZE));
    };

    lastPage = () => {
        const {items} = this.props;
        const userId = this.props.userId;
        const lastPage = Math.ceil(items.data.totalSize / PAGE_SIZE);
        const newPages = [lastPage - 2, lastPage - 1, lastPage];
        console.log(items.data);
        if (items.data.totalSize > this.state.page[2] * PAGE_SIZE) {
            this.setState({
                page: newPages,
                activePage: newPages[2]
            });
            this.props.dispatch(itemActions.getItems(userId, newPages[2] - 1, PAGE_SIZE));
        }
    }
}


function mapStateToProps(state) {
    const {items} = state.item;
    return {
        items
    };
}

const connectedPostsPage = connect(mapStateToProps)(PostsPage);
export {connectedPostsPage as PostsPage};
